@import url("./assets/styles.css");
@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --primaryColor: rgb(244, 242, 242);
  --secondaryColor: rgb(255, 255, 255);
  --heading-1: #505050;
  --themeOrangeLight: #fdf1ec;
  --themeOrange: #ec5f2a;
  --primaryDisableColor: rgba(0, 0, 0, 0.26);
  --emailTextColor: #3362dd;
  --totalStudentsColor: #7c7676;
  --InputTextGray: #928b8b;
  --fontsFamily: "Familjen Grotesk", sans-serif;
  --inter: "inter";
  --legendColor: #544f4f;
  --percentageColor: #bff5e7;
  --disableEmail: #989797;
  --mentorTextColor: #9c9696;
  --percentageTextColor: #201e1e;
  --breadCrumbBlack: #10100f;
  --intermediateBackground: #faad15;
  --negativePercentageBox: #ffcece;
  --trainingText: #5f5959;
  --checkboxBorder: #cecaca;
  --comparisionColor: #ef7143;
  --dashBoardEmptyText: #7e7777;
  --InputMessage: #696363;
  --BtnHoverColor: #f07f56;
  --errorsColor: #da2525;
  --loaderBackground: rgba(36, 36, 36, 0.772);
  --orangeDisabled: #f5aa8e;
  --lightGray: #f5f4f4;
  --borderInput: #eceaea;
  --topPerformer: #06d6a0;
  --topMentors: #71250a;
  --assignFadeBtn: #d8d5d5;
  --emailTextForAssign: #888181;
  --activityText: #4a4545;
  --otpBg: #f5f4f4;
  --fontMulish: "Mulish", sans-serif;
  --fontFamiljen: "Familjen Grotesk", sans-serif;
  --font100: 100;
  --font200: 200;
  --font300: 300;
  --font400: 400;
  --font500: 500;
  --font600: 600;
  --font700: 700;
  --font800: 800;
  --font900: 900;
  --optionBtnBg: #fce3d9;
  --lightGrayBorder: #dcdada;
  --lightBlue: #e3f2fd;
  --btnGray: #eceaea;
  --btnGrayHover: #dcdcdc;
  --alertBoxOrange: rgba(210, 121, 121, 0.3);
  --inerTextColor: #3f3b3b;
  --black: #100f0f;
  --orangeScore: #fad4c7;
}

h1,
h2,
h4 {
  font-family: var(--fontMulish) !important;
}

.linkactive {
  background-color: #201e1e !important;
  color: var(--themeOrange) !important;
}
.linkactive svg {
  color: var(--themeOrange) !important;
}
.styledTableRow:hover {
  background-color: #f0eaea !important;
}
#lms span {
  text-transform: uppercase !important;
}
.css-zxdg2z {
  width: 80% !important;
  height: 30rem !important;
}

.css-10hburv-MuiTypography-root {
  font-family: var(--fontFamiljen) !important;
  font-weight: var(--font400) !important;
}

.css-umn15m-MuiFormControl-root-MuiTextField-root,
.css-1qzhq3a-MuiFormControl-root {
  margin-bottom: 2rem !important;
}
.errorsPlaceholder {
  color: var(--errorsColor);
  font-family: var(--fontsFamily);
  border: 1.5px solid var(--errorsColor) !important;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f5f4f4;
  width: 100%;
  font-size: 1rem;
}
.errorsPlaceholder::placeholder {
  color: var(--errorsColor) !important;
}
.errors {
  color: var(--errorsColor) !important;
  font-size: 12px;
  font-family: var(--fontsFamily);
  display: grid;
  justify-content: end;
}
.BtnHover:hover {
  background-color: var(--BtnHoverColor) !important;
  transition: ease all 0.5s;
}
.BtnHoverDisable {
  background-color: var(--btnGray) !important;
}
.BtnHoverDisable:hover {
  background-color: var(--btnGrayHover) !important;
  transition: ease all 0.5s;
}
.BtnTaskHover:hover {
  background-color: var(--BtnHoverColor) !important;
  color: #fff !important;
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  content: URL("./assets/images/input_cross.svg");
  cursor: pointer;
}
.MuiOtpInput-TextField {
  border: 1px solid var(--borderInput) !important;
  outline: none;
  border-radius: 8px;
}
.otpWidth {
  width: 50px !important;
  height: 50px;
  border: 1.5px solid var(--borderInput);
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: var(--otpBg);
}
.otpWidth:focus-visible {
  outline: 1px solid var(--themeOrange);
  background-color: var(--themeOrangeLight);
}
.otpWidth:focus {
  border: 1.5px solid var(--themeOrange) !important;
  border-radius: 8px;
}
.otpContainer {
  justify-content: space-between;
}

.otpContainerWhatsapp {
  justify-content: space-between;
  gap: 0.5rem;
}

.activeOtp {
  outline: 1.5px solid var(--BtnHoverColor);
  background-color: var(--themeOrangeLight);
}
.headerGap {
  margin-bottom: 2rem;
}
.css-1g0tpee {
  min-height: 40px !important;
}
.rdt_TableCol input[type="checkbox"] {
  width: 18px !important;
  height: 18px !important;
}
.rdt_TableRow input[type="checkbox"] {
  width: 18px !important;
  height: 18px !important;
}
.rdt_TableCell:nth-child(6) {
  justify-content: end;
}
.rdt_TableCol:nth-child(6) {
  justify-content: end;
}
.rdt_TableCell {
  font-family: var(--fontFamiljen);
  font-weight: var(--font400);
  font-size: 14px;
}
.rdt_TableCol {
  font-family: var(--fontFamiljen);
  font-weight: var(--font500);
  font-size: 14px;
  color: var(--InputTextGray);
}

.MuiTooltip-tooltip {
  border-radius: 0px !important;
  background-color: #000000 !important;
  padding: 8px !important;
}
.MuiTooltip-arrow {
  color: #000000 !important;
}
.selected-row:nth-of-type(n) {
  background-color: var(--themeOrangeLight) !important;
}
input[type="checkbox"] {
  accent-color: var(--themeOrange);
}
.MuiDialog-paper {
  padding: 1.5rem 1rem !important;
  border-radius: 1rem !important;
  max-width: 23rem !important;
}
.seeAllPrograms {
  margin-bottom: 1.5rem;
}
.btnHidden {
  display: none !important;
}
.errorHandleMessage {
  color: var(--errorsColor);
  font-size: 0.75rem;
}
.styleSelect {
  padding: 1rem;
  outline: none;
  border: 1.5px solid #ccc;
  display: block;
  background-color: #f5f4f4;
  font-family: var(--fontsFamily);
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  color: var(--InputTextGray);
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.selectLabel {
  display: block;
  width: 100%;
  margin: 0;
  font-family: var(--fontsFamily);
  color: var(--legendColor);
  margin-bottom: 0.2rem;
  font-size: 14px;
}
.attachmentFileBTN {
  text-transform: capitalize;
  font-weight: var(--font500);
  font-family: var(--fontFamiljen);
  font-size: 12px;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: ease all 0.5s;
  color: var(--themeOrange);
  border-radius: 0.25rem;
}
.attachmentFileBTN:hover {
  background-color: var(--themeOrangeLight);
}
.attachmentVideoBTN {
  text-transform: capitalize;
  font-weight: var(--font500);
  font-family: var(--fontFamiljen);
  font-size: 14px;
  border-radius: 8px;
  border: 1.5px solid #ccc;
  background-color: #f5f4f4;
  cursor: pointer;
  padding: 14px 0;
  padding-left: 16px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: ease all 0.5s;
  color: var(--themeOrange);
  border-radius: 0.25rem;
}
.rdrDefinedRangesWrapper {
  display: none !important;
}
.notranslate {
  display: none !important;
  visibility: hidden !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.active {
  color: #ffffff !important;
  background-color: var(--themeOrange) !important;
  border-radius: 4px !important;
}

.draftCounter {
  padding: 0rem 0.4rem;
  background-color: #ccc;
  color: #fff;
  font-size: 10px;
  border-radius: 4px;
}
.active .draftCounter {
  background-color: #f49c7c;
}

.MuiAccordionDetails-root {
  padding: 8px 0px !important;
}
/* .MuiMenuItem-root:hover{
  background-color: var(--themeOrange)!important;
  color:white!important;
} */
.programLevel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 768px) {
  .otpWidth {
    width: 40px !important;
    height: 40px;
  }
}

/* Rectangular checkbox container */
.rectangular-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Hidden default checkbox input */
.checkbox-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Custom rectangular checkbox shape */
.checkbox-input + .checkbox-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.7rem;
  border: 1px solid #eceaea;
  background-color: white;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 0.25rem;
  font-size: 12px;
}

/* Custom rectangular checkbox checked state */
.checkbox-input:checked + .checkbox-label {
  border: 1px solid var(--themeOrange);
  color: var(--themeOrange);
}

.checkbox-input:disabled + .checkbox-label {
  border: 1px solid #dcdcdc;
  color: #dcdcdc;
  cursor: auto;
}

/* Checkbox label styling */
.checkbox-label {
  padding-left: 8px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
  background: var(--themeOrange);
}
.ql-toolbar {
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  background-color: #f5f4f4;
}
.ql-container {
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  /* background-color: #f5f4f4; */
}

.toolbarError {
  color: var(--errorsColor);
  font-family: var(--fontsFamily);
  border: 1.5px solid var(--errorsColor) !important;
  border-radius: 0.5rem;
  background-color: #f5f4f4;
  width: 100%;
  font-size: 1rem;
}
.toolbarError::placeholder {
  color: var(--errorsColor) !important;
}
.customImageInsert img {
  width: 2rem;
  height: 2rem;
}
.content img {
  width: 13.625rem;
  height: 6.25rem;
  object-fit: cover;
  border-radius: 0.25rem;
  cursor: pointer;
}
.react-player__preview {
  border-radius: 0.5rem;
}
